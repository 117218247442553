const AsvzNfcScanner = {
  ndef: null,
  scan: function () {
    if (!("NDEFReader" in window)) {
      window.dispatchEvent(new CustomEvent('nfcNotAvailable'));
    }
    else {
      this.ndef = new NDEFReader();
      // ndef.scan({ signal: this.abortController.signal });
      this.ndef.scan();
      console.log("> Scan started");
      this.ndef.addEventListener("readingerror", () => {
        console.log("Cannot read data from the NFC tag. Try another one?");
        window.dispatchEvent(new CustomEvent('nfcScannedFailed'));
      });

      this.ndef.addEventListener("reading", ({ message, serialNumber }) => {
        nfcSerial = serialNumber;
        window.dispatchEvent(new CustomEvent('nfcScanned', { detail: serialNumber }));
      });
    }
  },
  stopScanning: function() {
    console.log("> Scan stopt");
    this.ndef = null;
  }
}